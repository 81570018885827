<template>
  <div>
    <mdb-card cascade>
      <mdb-view
        class="card-header-reversed pl-2 pt-1 pb-1 d-flex justify-content-between align-items-center"
        cascade
      >
        <div
          class="title-card-tec card-header-title color-primary-reversed"
          style="font-weight: 400"
        >
          Méthodes de paiements
        </div>
        <mdb-btn
          class="float-right"
          tag="a"
          color="primary"
          icon="plus"
          size="sm"
          small
          @click="addOrEdit(null)"
        >
        </mdb-btn>
      </mdb-view>

      <mdb-card-body :class="'card-reversed'" cascade v-loading="loading">
        <mdb-row>
          <mdb-col sm="12" md="4" class="mb-n4">
            <mdb-select
              flipOnScroll
              label="Nombre par page"
              outline
              class=""
              @getValue="nbrPerPageSelected"
              v-model="dataPagination.pagination.perPageOptions"
            />
          </mdb-col>
          <mdb-col sm="12" md="4" class="mb-n4"> </mdb-col>
          <mdb-col sm="12" md="4" class="mb-n3">
            <mdb-input
              inputClass="border-reversed color-normal-reversed"
              labelClass=" color-normal-reversed"
              icon="search"
              outline
              type="text"
              v-model="dataPagination.searchQuery"
              placeholder="Rechercher"
              aria-label="Search"
            />
          </mdb-col>
        </mdb-row>

        <mdb-tbl responsiveMd >
          <mdb-tbl-head>
            <tr class="color-normal-reversed">
              <th v-for="column in data.columns" :key="column.name">
                {{ column.label }}
              </th>
            </tr>
          </mdb-tbl-head>
          <mdb-tbl-body>
            <tr
              class="tec-table-row color-normal-reversed"
              v-for="row in queriedData"
              :key="row.name"
            >
              <td class="tec-table-cell">
                <span
                  v-if="row.is_active === 1"
                  class="badge rounded-pill bg-success"
                  style="height: 10px; width: 10px; display: inline-block"
                ></span>
                <span
                  v-if="row.is_active === 0"
                  class="badge rounded-pill bg-danger"
                  style="height: 10px; width: 10px; display: inline-block"
                ></span>
                {{ row.designation }}
              </td>
              <td class="tec-table-cell text-uppercase">
                {{ row.code_mcf }}
              </td>
              <td class="tec-table-cell text-uppercase">
                {{ row.moyens }}
              </td>
              <td class="d-flex justify-content-end">
                <el-dropdown trigger="click">
                  <span
                    class="el-dropdown-link"
                    style="cursor: pointer; font-weight: 500"
                  >
                    Actions<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu class="dropdown-el-tec" slot="dropdown">
                    <el-dropdown-item
                      @click.native="addOrEdit(row)"
                      class="dropdown-item-el-tec"
                      icon="el-icon-edit"
                      >Modifier</el-dropdown-item
                    >
                    <el-dropdown-item
                      @click.native="deleteDepot(row.id)"
                      class="dropdown-item-el-tec"
                      icon="el-icon-delete"
                      >Supprimer</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </td>
            </tr>
          </mdb-tbl-body>
        </mdb-tbl>

        <div class="d-flex justify-content-end">
          <tec-pagination
            v-model="dataPagination.pagination.currentPage"
            :per-page="parseFloat(dataPagination.pagination.perPage)"
            :total="dataPagination.pagination.total"
          ></tec-pagination>
        </div>
      </mdb-card-body>
    </mdb-card>

    <mdb-modal
      centered
      size="lg"
      direction="top"
      position="top"
      :show="modalPayment.show"
    >
      <form @submit.prevent="modalPayment.edit ? update() : save()">
        <mdb-modal-body class="modal-tec">
          <div class="modal-times-tec" @click="modalPayment.show = false">
            <i class="fas fa-times"></i>
          </div>
          <mdb-modal-title
            class="text-primary white-text"
            style="font-weight: 500 !important; font-size: 1.5rem !important"
            >{{ modalPayment.title }}</mdb-modal-title
          >
          <fieldset>
            <mdb-row>
              <mdb-col sm="12" md="12">
                <mdb-input
                  @change="methodFormatter"
                  :validation="$store.state.payment.errors.designation ? true : false"
                  :invalidFeedback="$store.state.payment.errors.designation"
                  v-model="form.designation"
                  size="lg"
                  outline
                  label="Désignatin"
                ></mdb-input>
              </mdb-col>
              <mdb-col sm="12" md="6">
                <mdb-input
                  @change="methodFormatter"
                  :validation="$store.state.payment.errors.moyens ? true : false"
                  :invalidFeedback="$store.state.payment.errors.moyens"
                  v-model="form.moyens"
                  size="lg"
                  outline
                  label="Methode"
                ></mdb-input>
              </mdb-col>
              <mdb-col sm="12" md="6">
                <mdb-input
                  :validation="$store.state.payment.errors.code_mcf ? true : false"
                  :invalidFeedback="$store.state.payment.errors.code_mcf"
                  v-model="form.code_mcf"
                  @change="codeFormatter"
                  size="lg"
                  outline
                  label="Code MCF"
                ></mdb-input>
              </mdb-col>
            </mdb-row>
          </fieldset>
          <fieldset>
            <legend>Option Avancée</legend>
            <mdb-row>
              <mdb-col sm="12" md="4" class="">
                <label for="">Etat de la méthode ?</label>
                <mdb-switch
                  v-model="form.is_active"
                  offLabel="Désactivé"
                  onLabel="Activé"
                />
              </mdb-col>
            </mdb-row>
          </fieldset>
          <div class="d-flex justify-content-end">
            <mdb-btn size="md" color="grey" @click="modalPayment.show = false"
              >Fermer</mdb-btn
            >
            <mdb-btn size="md" color="success" type="submit" :disabled="modalPayment.btn">
              <span v-if="!modalPayment.btn">Sauvegarder</span>
              <span
                v-if="modalPayment.btn"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span v-if="modalPayment.btn" class="pl-2">Loading...</span>
            </mdb-btn>
          </div>
        </mdb-modal-body>
      </form>
    </mdb-modal>
  </div>
</template>

<script>
import TecPagination from "@/tba/components/Pagination";
import {
  mdbCard,
  mdbCardBody,
  mdbView,
  mdbBtn,
  mdbSwitch,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbRow,
  mdbCol,
  mdbSelect,
  mdbInput,
  mdbModal,
  mdbModalTitle,
  mdbModalBody,
} from "mdbvue";
export default {
  name: "payment-methods",
  components: {
    mdbCard,
    mdbCardBody,
    mdbView,
    mdbBtn,
    mdbSwitch,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbRow,
    mdbCol,
    mdbSelect,
    mdbInput,
    mdbModal,
    mdbModalTitle,
    mdbModalBody,
    TecPagination,
  },

  data() {
    return {
      loading: false,
      dataPagination: {
        pagination: {
          perPage: 5,
          currentPage: 1,
          perPageOptions: [
            { text: "5", value: "5", selected: true },
            { text: "10", value: "10" },
            { text: "25", value: "25" },
            { text: "50", value: "50" },
            { text: "100", value: "100" },
          ],
          total: 0,
        },
        searchQuery: "",
        propsToSearch: ["moyens", "designation", "code_mcf"],
      },

      data: {
        columns: [
          {
            label: "Désignation",
            field: "designation",
            sort: true,
          },
          {
            label: "Moyens",
            field: "moyens",
            sort: true,
          },

          {
            label: "MCF",
            field: "code_mcf",
            sort: true,
          },

          {
            label: "",
            field: "action",
            sort: true,
          },
        ],
        rows: [],
      },

      form: {
        id: "",
        moyens: "",
        designation: "",
        code_mcf: "",
        is_active: true,
      },

      modalPaymentDetails: {
        show: false,
        edit: false,
        title: "Détails de la methode paiement",
        content: {},
      },
      modalPayment: {
        show: false,
        edit: false,
        title: "Ajouter une methode paiement",
        content: {},
        btn: false,
      },
    };
  },

  methods: {
    //Fonction de fonctionnement
    addOrEdit(data = null) {
      if (data instanceof Object) {
        this.modalPayment.title = "Modifier une methode paiement";
        this.modalPayment.content = data;
        this.modalPayment.edit = true;
        this.formRest(data);
      } else {
        this.modalPayment.title = "Ajouter une methode paiement";
        this.modalPayment.content = {};
        this.modalPayment.edit = false;
        this.cleanForm();
      }

      this.modalPayment.show = true;
    },

    cleanForm(type) {
      if (type === "payment") {
        this.form.id = "";
        this.form.moyens = "";
        this.form.designation = "";
        this.form.code_mcf = "";
        this.form.is_active = true;
      }
    },

    formRest(data) {
      this.form.id = data.id;
      this.form.moyens = data.moyens;
      this.form.designation = data.designation;
      this.form.code_mcf = data.code_mcf;
      this.form.is_active = data.is_active === 1 ? true : false;
    },

    details() {
      //
    },

    async loadPaymentMethods() {
      this.$nprogress.start();
      this.loading = !this.loading;
      await this.$store
        .dispatch("payment/findAll")
        .then((response) => {
          this.$nprogress.done();
          this.loading = !this.loading;
          this.data.rows = response.data.paymentMethods;
        })
        .catch((error) => {
          this.$nprogress.done();
          this.loading = !this.loading;
          this.$notify({
              
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async save() {
      this.$store.commit("payment/SET_CLEAN");
      this.modalPayment.btn = !this.modalPayment.btn;

      await this.$store
        .dispatch("payment/save", this.form)
        .then((response) => {
          this.modalPayment.show = false;
          this.modalPayment.btn = !this.modalPayment.btn;
          this.$notify({
                 
            message: response.data.message,
            type: "success",
          });
          this.cleanForm("payment");
          this.loadPaymentMethods();
        })
        .catch((error) => {
          this.modalPayment.btn = !this.modalPayment.btn;
          if (error.response.data.errors) {
            this.$store.commit("payment/SET_MOYENS", error.response.data.errors.moyens);
            this.$store.commit("payment/SET_CODE", error.response.data.errors.code_mcf);
          }

          this.$notify({
              
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    methodFormatter() {
      this.form.moyens = this.form.moyens.toUpperCase();
    },

    async update() {
      this.$store.commit("payment/SET_CLEAN");
      this.modalPayment.btn = !this.modalPayment.btn;
      await this.$store
        .dispatch("payment/update", this.form)
        .then((response) => {
          this.modalPayment.show = false;
          this.modalPayment.btn = !this.modalPayment.btn;
          this.$notify({
                 
            message: response.data.message,
            type: "success",
          });
          this.cleanForm("payment");
          this.loadPaymentMethods();
        })
        .catch((error) => {
          this.modalPayment.btn = !this.modalPayment.btn;
          if (error.response.data.errors) {
            this.$store.commit("payment/SET_MOYENS", error.response.data.errors.moyens);
            this.$store.commit("payment/SET_CODE", error.response.data.errors.code_mcf);
          }
          this.$notify({
              
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async deleteDepot(id) {
      let res = await this.$store.dispatch("swal/doYouDelete");
      if (res.isDismissed) {
        return;
      }
      await this.$store
        .dispatch("payment/delete", id)
        .then((response) => {
          this.$notify({
                 
            message: response.data.message,
            type: "success",
          });
          this.loadPaymentMethods();
        })
        .catch((error) => {
          this.$notify({
              
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    // Systeme de datatable
    totalCalcul() {
      return (this.dataPagination.pagination.total = this.data.rows.length);
    },

    searchQ() {
      if (!this.dataPagination.searchQuery) {
        // return this.pagedData;
        return new Error("Nothing To Search");
      }

      let result = this.data.rows.filter((row) => {
        let isIncluded = false;
        for (let key of this.dataPagination.propsToSearch) {
          let rowValue = "";
          rowValue = row[key].toString().toLowerCase();
          if (
            rowValue.includes &&
            rowValue.includes(this.dataPagination.searchQuery.toLowerCase())
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });
      this.dataPagination.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },

    nbrPerPageSelected(value) {
      let nbr = value;

      return this.nbrPerPage(nbr);
    },

    nbrPerPage(nbr) {
      return (this.dataPagination.pagination.perPage = nbr);
    },

    codeFormatter() {
      this.form.code_mcf = this.form.code_mcf.toUpperCase();
    },

    moyensFormatter() {
      this.form.moyens = this.form.moyens.toLowerCase().toCapitalize();
    },
  },

  created() {
    this.loadPaymentMethods();
  },

  computed: {
    pageData() {
      return this.data.rows.slice(this.from, this.to);
    },

    to() {
      let highBound = this.from + this.dataPagination.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },

    queriedData() {
      let result = this.searchQ();
      return result instanceof Error ? this.pageData : result;
    },

    from() {
      return (
        this.dataPagination.pagination.perPage *
        (this.dataPagination.pagination.currentPage - 1)
      );
    },

    total() {
      return this.totalCalcul();
    },
  },
};
</script>
