<template>
    <div>
     <mdb-card cascade>
        <mdb-view class="card-header-reversed pl-2 pt-1 pb-1 d-flex justify-content-between align-items-center " cascade>
            <div class="title-card-tec card-header-title color-primary-reversed" style="font-weight:400">Types de facture</div>
            <mdb-btn
                class="float-right"
                tag="a"
                color="primary"
                icon="plus"
                size="sm" 
                small
                @click="addOrEdit(null)"
            >
            </mdb-btn>
        </mdb-view>

        <mdb-card-body :class="'card-reversed'" cascade v-loading="loading">
            <mdb-row>
                <mdb-col sm="12" md='4' class="mb-n4">
                <mdb-select flipOnScroll
                 wrapperClass=""   labelClass=" color-normal-reversed" caretClass="color-primary-reversed " 
                    outline
                    class=""
                    @getValue="nbrPerPageSelected"
                    v-model="dataPagination.pagination.perPageOptions"
                />
                </mdb-col>
                <mdb-col sm="12" md='4' class="mb-n4">
                </mdb-col>
                <mdb-col sm="12" md='4' class="mb-n3">
                <mdb-input
                    inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed" 
                    icon="search"
                    outline
                    type="text"
                    v-model="dataPagination.searchQuery"
                    placeholder="Rechercher"
                    aria-label="Search"
                    />
                </mdb-col>
            </mdb-row> 

            <mdb-tbl responsiveMd >
                <mdb-tbl-head>
                    <tr class="color-normal-reversed">
                        <th v-for="column in data.columns" :key="column.name" >
                            {{column.label}}
                        </th>
                    </tr>
                </mdb-tbl-head>
                <mdb-tbl-body>
                    <tr class="tec-table-row  color-normal-reversed"  v-for="row in queriedData" :key="row.name">
                        <td class="tec-table-cell text-uppercase" > 
                           {{ row.ref }}
                        </td>
                        <td class="tec-table-cell" > 
                         <span v-if="row.is_active === 1" class="badge rounded-pill bg-success" style="height:10px; width:10px;display:inline-block"></span>
                         <span v-if="row.is_active === 0" class="badge rounded-pill bg-danger" style="height:10px; width:10px;display:inline-block"></span>
                           {{ row.type }}</td>
                        <td class="tec-table-cell text-uppercase" > 
                           {{ row.nature }}
                        </td>
                        <td class="d-flex justify-content-end">
                            <el-dropdown trigger="click">
                                <span class="el-dropdown-link" style="cursor:pointer; font-weight:500" >
                                    Actions<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu class="dropdown-el-tec" slot="dropdown">
                                    <el-dropdown-item @click.native="addOrEdit(row)" class="dropdown-item-el-tec" icon="el-icon-edit">Modifier</el-dropdown-item>
                                    <el-dropdown-item @click.native="deleteDepot(row.id)" class="dropdown-item-el-tec" icon="el-icon-delete">Supprimer</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </td>
                    </tr>
                </mdb-tbl-body>
            </mdb-tbl>

            <div class="d-flex justify-content-end">
                 <tec-pagination
                    v-model="dataPagination.pagination.currentPage"
                    :per-page="parseFloat(dataPagination.pagination.perPage)"
                    :total="dataPagination.pagination.total"
                ></tec-pagination>
            </div>
        </mdb-card-body>
     </mdb-card>

    <mdb-modal
        centered
        size="lg"
        direction="top"
        position="top"
        :show="modalType.show"
    >
        <form @submit.prevent='modalType.edit ? update() : save()'>
        <mdb-modal-body class="modal-tec">
                <div class="modal-times-tec "  @click="modalType.show = false"> <i class="fas fa-times"></i> </div>
                <mdb-modal-title class="text-primary white-text" style="font-weight:500 !important; font-size:1.5rem !important;">{{ modalType.title }}</mdb-modal-title>
                <fieldset>
                    <mdb-row>
                        <mdb-col sm="12" md="12">
                            <mdb-input
                             :validation="$store.state.type_invoice.errors.type ? true :false" :invalidFeedback="$store.state.type_invoice.errors.type"
                                v-model="form.type"
                              size="lg" outline label="Type Facture" ></mdb-input>
                        </mdb-col>
                        <mdb-col sm="12" md="6">
                            <mdb-input
                             :validation="$store.state.type_invoice.errors.nature ? true :false" :invalidFeedback="$store.state.type_invoice.errors.nature"
                                v-model="form.nature"
                                @change="natureFormatter"
                              size="md" outline label="Nature" ></mdb-input>
                        </mdb-col>
                         <mdb-col sm="12" md="6">
                            <mdb-input
                             :validation="$store.state.type_invoice.errors.ref ? true :false" :invalidFeedback="$store.state.type_invoice.errors.ref"
                                v-model="form.ref"
                                @change="refFormatter"
                              size="md" outline label="Reference" ></mdb-input>
                        </mdb-col>
                        
                    </mdb-row>
                </fieldset>
                <fieldset>
                    <legend>Option Avancée</legend>
                    <mdb-row>
                        <mdb-col sm="12" md="4" class="" >
                            <label for="">Etat du type?</label>
                            <mdb-switch v-model="form.is_active" offLabel="Désactivé" onLabel="Activé"  />
                        </mdb-col>
                    </mdb-row>
                </fieldset>
                <div class="d-flex justify-content-end">
                <mdb-btn size="md" color="grey" @click="modalType.show = false">Fermer</mdb-btn>
                <mdb-btn size="md" color="success" type="submit" :disabled="modalType.btn">
                    <span v-if="!modalType.btn">Sauvegarder</span>
                    <span v-if="modalType.btn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span v-if="modalType.btn" class="pl-2">Loading...</span>
                </mdb-btn>
            </div>
        </mdb-modal-body>
        </form>
    </mdb-modal>
    </div>
</template>

<script>
import TecPagination from '@/tba/components/Pagination'
import {
    mdbCard,mdbCardBody,
    mdbView,
    mdbBtn,
    mdbSwitch,
    mdbTbl,mdbTblHead,mdbTblBody,
    mdbRow,mdbCol,
    mdbSelect,mdbInput,
    mdbModal,mdbModalTitle,mdbModalBody
} from 'mdbvue'
export default {
    name:"type_invoice-methods",
    components:{
        mdbCard,mdbCardBody,
        mdbView,
        mdbBtn,
        mdbSwitch,
        mdbTbl,mdbTblHead,mdbTblBody,
        mdbRow,mdbCol,
        mdbSelect,mdbInput,
        mdbModal,mdbModalTitle,mdbModalBody,
        TecPagination
    },

    data(){
        return {
            loading:false,
            dataPagination: {
                pagination: {
                    perPage: 5,
                    currentPage: 1,
                    perPageOptions: [
                        {text:"5",value:"5", selected: true},
                        {text:"10",value:"10"},
                        {text:"25",value:"25"},
                        {text:"50",value:"50"},
                        {text:"100",value:"100"},
                    ],
                    total: 0,
                },
                searchQuery: "",
                propsToSearch: [
                    "type",
                    "nature",
                    "ref"
                ],
            },

            data:{
                columns: [
                    {
                        label: "Réference",
                        field: "ref",
                        sort: true,
                    },

                    {
                        label: "Type",
                        field: "type",
                        sort: true,
                    },

                    {
                        label: "Nature",
                        field: "nature",
                        sort: true,
                    },
                    
                    {
                        label: "",
                        field: "action",
                        sort: true,
                    },
                ],
                rows:[]
            },

            form:{
                id:"",
                type:"",
                nature:"",
                ref:"",
                is_active:true
            },

            modalTypeDetails: {
                show: false,
                edit: false,
                title: "Détails du type de facture",
                content: {},
            },
            modalType: {
                show: false,
                edit: false,
                title: "Ajouter un type de facture",
                content: {},
                btn:false
            },
        }
    },

    methods:{
        //Fonction de fonctionnement
        addOrEdit(data = null) {
            if (data instanceof Object) {
                this.modalType.title = "Modifier un type de facture";
                this.modalType.content = data;
                this.modalType.edit = true;
                this.formRest(data);
            } else {
                this.modalType.title = "Ajouter un type de facture";
                this.modalType.content = {};
                this.modalType.edit = false;
                this.cleanForm();
            }

            this.modalType.show = true;
        },

        cleanForm(type) {
            if (type === 'type_invoice') {
				this.form.id = ""
                this.form.type = ""
                this.form.nature = ""
                this.form.ref = ""
                this.form.is_active = true
			}
        },

        formRest(data) {
            this.form.id = data.id
            this.form.type = data.type
            this.form.nature = data.nature
            this.form.ref = data.ref
            this.form.is_active = data.is_active === 1 ? true: false
        },

        details() {
            // 
        },

        async loadTypeInvoice () {
			this.$nprogress.start()
            this.loading = !this.loading
            await this.$store.dispatch('type_invoice/findAll')
            .then(response => {
				this.$nprogress.done()
                this.loading = !this.loading
                this.data.rows = response.data.typeInvoices
            }).catch((error) => {
				this.$nprogress.done()
                this.loading = !this.loading
                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
            })
        },

        async save() {
            this.$store.commit('type_invoice/SET_CLEAN')
            this.modalType.btn = !this.modalType.btn

            await this.$store.dispatch('type_invoice/save', this.form)
			.then((response) => {
				this.modalType.show = false
                this.modalType.btn = !this.modalType.btn
                    this.$notify({
                         
                        message: response.data.message,
                        type: 'success'
                    })
				this.cleanForm('type_invoice')
                this.loadTypeInvoice()
			})
			.catch((error) => {
                this.modalType.btn = !this.modalType.btn
                if (error.response.data.errors) {
                    this.$store.commit('type_invoice/SET_TYPE', error.response.data.errors.type)
                    this.$store.commit('type_invoice/SET_NATURE', error.response.data.errors.nature)
                    this.$store.commit('type_invoice/SET_REF', error.response.data.errors.ref)
                }

                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
			}) 
        },

        async update() {
            this.$store.commit('type_invoice/SET_CLEAN')
            this.modalType.btn = !this.modalType.btn
			await this.$store.dispatch('type_invoice/update', this.form)
			.then((response) => {
				this.modalType.show = false
                this.modalType.btn = !this.modalType.btn
                    this.$notify({
                         
                        message: response.data.message,
                        type: 'success'
                    })
				this.cleanForm('type_invoice')
                this.loadTypeInvoice()
			})
			.catch((error) => {
                this.modalType.btn = !this.modalType.btn
                if (error.response.data.errors) {
                    this.$store.commit('type_invoice/SET_TYPE', error.response.data.errors.type)
                    this.$store.commit('type_invoice/SET_NATURE', error.response.data.errors.nature)
                    this.$store.commit('type_invoice/SET_REF', error.response.data.errors.ref)
                }
                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
			})  
        },

        async deleteDepot(id){

            let res = await this.$store.dispatch("swal/doYouDelete");
            if (res.isDismissed) {
                return;
            }
			await this.$store.dispatch('type_invoice/delete',id)
                .then((response) => {
                    this.$notify({
                         
                        message: response.data.message,
                        type: 'success'
                    })
                    this.loadTypeInvoice()
                })
                .catch(error =>{
                    this.$notify({
                           
                        message: error.response.data.message  || error.response.data.error,
                        type: 'danger'
                    })
                })
			
		},

        // Systeme de datatable
        totalCalcul () {
            return this.dataPagination.pagination.total = this.data.rows.length;
        },

        searchQ () {
            if (!this.dataPagination.searchQuery) {
                // return this.pagedData;
                return new Error('Nothing To Search')
            }
            
            let result = this.data.rows.filter((row) => {
                let isIncluded = false;
                for (let key of this.dataPagination.propsToSearch) {
                    let rowValue = "";
                    rowValue = row[key].toString().toLowerCase();
                    if (rowValue.includes && rowValue.includes(this.dataPagination.searchQuery.toLowerCase())) {
                    isIncluded = true;
                    }
                }
                return isIncluded;
            });
            this.dataPagination.pagination.total = result.length;
            return result.slice(this.from, this.to);
        },

        nbrPerPageSelected(value){
            let nbr = value;
            
            return this.nbrPerPage(nbr)
        },

        nbrPerPage (nbr) {
           return this.dataPagination.pagination.perPage = nbr
        },

        natureFormatter(){
            this.form.nature = this.form.nature.toUpperCase();
        },
        refFormatter(){
            this.form.ref = this.form.ref.toUpperCase();
        }
    },

    created() {
        this.loadTypeInvoice()    
    },

    computed: {
        pageData () {
            return this.data.rows.slice(this.from, this.to);
        },

        to() {
            let highBound = this.from + this.dataPagination.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },

        queriedData() {
            let result = this.searchQ();
            return (result instanceof Error) ? this.pageData : result;
        },

        from() {
            return this.dataPagination.pagination.perPage * (this.dataPagination.pagination.currentPage - 1);
        },

        total() { 
            return this.totalCalcul();
        },
    }
}
</script>