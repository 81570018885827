<template>
    <div class="container-fluid">
        <mdb-row>
            <mdb-col sm="12" md="12"  lg="6" class=" mb-4">
                <payment></payment>
            </mdb-col>
            <mdb-col sm="12" md="12" lg="6" >
                <type></type>
            </mdb-col>
        </mdb-row>
    </div>
</template>

<script>
import payment from './PaymentMethod'
import type from './TypeInvoice'
import {
    mdbRow,mdbCol,
} from 'mdbvue'
export default {
    metaInfo: {
      title: 'TECBILL APP',
      titleTemplate: '%s - METHODE DE PAIEMENT ET TYPE FACTURE',
      htmlAttrs: {
        lang: 'fr',
        amp: true
      }
    },
    components:{
        mdbRow,mdbCol,
        payment,
        type
    },
    data(){
        return {
            //
        }
    }
}
</script>